<template>
  <div id="courier">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "courier",
  data() {
    return {};
  },
  created() {
    document.title = "配送员";
    let path = this.$route.query.path;
    let query = "";
    if (this.$route.query.query != "undefined") {
      query = this.$route.query.query;
    }
    if (!this.$utils.rule.empty(query)) {
      query = JSON.parse(decodeURIComponent(query));
    }
    if (path) {
      this.$router.replace({ path: "courier/" + path, query: query });
    }
  },
};
</script>
<style scoped>
#courier {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow: scroll;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
